// User Menu
import './vendor/jquery-accessibleMegaMenu.js';
import { amplify } from './acs-amplify.js';
export function usermenu() {
  if (typeof acs.ui === 'undefined') {
    acs.ui = {};
  }

  acs.userMenu = {};
  acs.userMenu.init = function () {
    acs.userMenu.build();

    if (window.matchMedia('(max-width:767px)').matches) {
      acs.userMenu.build();
      acs.userMenu.mobile();
    } else if (window.matchMedia('(min-width:768px)').matches && !document.querySelector('html').classList.contains('desktopMenuOn')) {
      acs.userMenu.build();
      acs.userMenu.desktop();
    }

    window.matchMedia('(max-width:767px)').addEventListener('change', function (event) {
      if (event.matches) {
        acs.userMenu.build();
        acs.userMenu.mobile();
      }
    });

    window.matchMedia('(min-width:768px)').addEventListener('change', function (event) {
      if (event.matches && !document.querySelector('html').classList.contains('desktopMenuOn')) {
        acs.userMenu.build();
        acs.userMenu.desktop();
      }
    });
  };

  acs.userMenu.build = function () {
    var memberMenuTarget = document.getElementById('member-menu');

    var userMenuHTML = '';
    userMenuHTML += '<ul class="members js--member-menu" role="menu">';
    if (acs.user.ismember) {
      userMenuHTML +=
        '<li class="js--member-menu__item" role="none"><a class="hat__join" role="menuitem" href="' + acs.sso.omaUrl + '">Join ACS</a></li>';
    } else {
      userMenuHTML +=
        '<li class="js--member-menu__item" role="none"><a class="hat__join" role="menuitem" href="' + acs.sso.acsDonateUrl + '">Donate</a></li>';
    }
    userMenuHTML += '<li class="js--member-menu__item" role="none">';
    userMenuHTML +=
      '<a href="#" id="my-account-button" class="' +
      (!!acs.user.loggedin ? 'my-account__button logged-in' : 'my-account__button') +
      '" role="menuitem" aria-haspopup="true" aria-owns="my-account-menu" aria-controls="my-account-menu" aria-expanded="false" aria-label="' +
      (!!acs.user.loggedin ? 'Login menu, you are logged in as ' + acs.sso.fullname : 'Login menu, you are not logged in') +
      '"><span class="desktop-version">' +
      (!!acs.user.loggedin ? acs.user.firstname : 'Log In') +
      '</span></a>';

    userMenuHTML +=
      '<div id="my-account-menu" class="my-account js--member-menu__sub"  aria-expanded="false" aria-hidden="true" aria-expanded="false" aria-labelledby="my-account-button" role="group">';

    if (!!acs.user.loggedin) {
      userMenuHTML += '<h3 class="my-account__welcome">Welcome, ' + acs.user.firstname + ' ' + acs.user.lastname + '</h3>';
    } else {
      userMenuHTML += '<a href="' + acs.sso.loginUrl + '" class="my-account__login">Log in to My Account</a>';
    }
    if (acs.sso.acctManagementUrl) {
      userMenuHTML += '<a href="' + acs.sso.acctManagementUrl + '" class="my-account__manage">Manage your Account</a>';
    }
    userMenuHTML += '<ul class="my-account__list">';
    userMenuHTML +=
      '<li class="my-account__item" role="menuitem"><a href="' +
      acs.sso.acsMembershipBenefitsUrl +
      '" class="my-account__link">ACS Member Benefits</a></li>';
    userMenuHTML +=
      '<li class="my-account__item" role="menuitem"><a href="' + acs.sso.emailSubscription + '" class="my-account__link">Email Preferences</a></li>';
    if (!!acs.user.isacsnetworkmember || !acs.user.loggedin) {
      userMenuHTML +=
        '<li class="my-account__item" role="menuitem"><a href="' +
        acs.sso.acsNetworkUrl +
        '" class="my-account__link">' +
        (!acs.user.loggedin ? 'ACS Communities' : 'Access ACS Communities') +
        '</a></li>';
    } else {
      userMenuHTML +=
        '<li class="my-account__item" role="menuitem"><a href="' +
        acs.sso.acsNetworkOptInUrl +
        '" class="my-account__link">Join ACS Network</a></li>';
    }
    userMenuHTML +=
      '<li class="my-account__item" role="menuitem"><a href="' + acs.sso.techDivision + '" class="my-account__link">ACS Divisions</a></li>';
    if (!!acs.user.loggedin) {
      userMenuHTML += '<li class="my-account__item" role="menuitem"><a href="' + acs.sso.localLogout + '" class="my-account__link">Log Out</a></li>';
    }
    userMenuHTML += '</ul>';
    userMenuHTML += '<a href="/content/acs/en/help.html" class="my-account__help">Help / Give Feedback</a>';
    userMenuHTML += '</div>';
    userMenuHTML += '</li>';
    userMenuHTML += '</ul>';

    memberMenuTarget.innerHTML = userMenuHTML;
  };

  acs.userMenu.desktop = function () {
    if (acs.ui.hasNewMenu) {
      $('#member-menu').accessibleMegaMenu({
        /* prefix for generated unique id attributes, which are required
				 to indicate aria-owns, aria-controls and aria-labelledby */
        uuidPrefix: 'js--member-menu',

        /* css class used to define the megamenu styling */
        menuClass: 'js--member-menu',

        /* css class for a top-level navigation item in the megamenu */
        topNavItemClass: 'js--member-menu__item',

        /* css class for a megamenu panel */
        panelClass: 'js--member-menu__sub',

        /* css class for a group of items within a megamenu panel */
        panelGroupClass: 'js--member-menu-sub__list',

        /* css class for the hover state */
        hoverClass: 'hover',

        /* css class for the focus state */
        focusClass: 'focus',

        /* css class for the open state */
        openClass: 'open',
      });
    }
  };

  acs.userMenu.mobile = function () {
    $('#member-menu').accessibleMegaMenu.destroy;

    let myAccountButton = document.querySelector('.my-account__button');

    myAccountButton.removeEventListener('click', showMenu);
    myAccountButton.addEventListener('click', showMenu);

    function showMenu() {
      if (!myAccountButton.classList.contains('showit')) {
        document.querySelector('.acsHeader').classList.remove('menu-on');
        document.querySelectorAll('.showit').forEach(element => element.classList.remove('showit'));
        document.querySelectorAll('.hide').forEach(element => element.classList.remove('hide'));
        document.querySelector('.menu').classList.remove('search-on');
        document.querySelector('body').classList.remove('noscroll');
      }
      document.querySelector('.menu__logo').classList.remove('hide');
      document.querySelector('body').classList.toggle('noscroll');
      document.querySelector('.my-account').classList.toggle('showit');
      myAccountButton.classList.toggle('showit');
    }
  };

  var methods = {
    init: function () {
      acs.userMenu.init();

      amplify.subscribe('sso.status.changed', function (newData) {
        acs.userMenu.init();
      });
    },
    update: function () {
      acs.userMenu.init();
    },
  };

  // extend the acs namespace with the login menu UI
  Object.assign(acs.ui, { loginMenu: methods });
}
usermenu();
